import {useEffect, useState} from 'react';
import {StatesButtonStates} from 'wix-ui-tpa/cssVars';
import {useControllerProps} from '../../../Widget/ControllerContext';
import {useMemberDetailsData} from '../../../MemberDetails/WithMemberDetailsData';
import {ADD_NEW_ADDRESS_ID} from '../../../constants';
import {StepId} from '../../../../../types/checkoutApp.types';
import {useBillingData} from './WithBillingData';
import {shouldHideBillingDetailsSection} from '../../../../../domain/utils/billingDetails.utils';
import {usePaymentsApi} from '../../../../../common/components/WithPaymentsApi/WithPaymentsApi';
import {useExperiments} from '@wix/yoshi-flow-editor';
import {SPECS} from '../../../../../common/constants';

export function UsePlaceOrderButtonState() {
  const {
    checkoutStore: {updateCheckoutError, isPlaceOrderButtonDisabled, placeOrderError, isFastFlow, checkout},
    formsStore: {areFormsLoaded},
    stepsManagerStore: {activeStep},
    memberStore: {isMember},
    paymentStore: {shouldRequirePayment},
  } = useControllerProps();
  const {activePaymentId} = usePaymentsApi();
  const {experiments} = useExperiments();

  const {editMode, selectedAddressesServiceId, areMemberDetailsValid} = useMemberDetailsData();
  const {isShippingValidForBilling, billingSameAsShipping} = useBillingData();

  const [placeOrderButtonState, setPlaceOrderButtonState] = useState(StatesButtonStates.IDLE);

  const [shouldPlaceOrderButtonBeDisabled, setShouldPlaceOrderButtonBeDisabled] = useState(
    (!isFastFlow && activeStep.stepId === StepId.paymentAndPlaceOrder && shouldRequirePayment && !areFormsLoaded) ||
      isPlaceOrderButtonDisabled
  );

  const ShouldRecalculatePlaceOrderButtonStateForMembers = experiments.enabled(
    SPECS.ShouldRecalculatePlaceOrderButtonStateForMembers
  );

  useEffect(() => {
    /* istanbul ignore next */
    if (!ShouldRecalculatePlaceOrderButtonStateForMembers && isFastFlow) {
      setShouldPlaceOrderButtonBeDisabled(isPlaceOrderButtonDisabled);
    }
  }, [isPlaceOrderButtonDisabled, isFastFlow, ShouldRecalculatePlaceOrderButtonStateForMembers]);

  useEffect(() => {
    /* istanbul ignore next */
    if (!ShouldRecalculatePlaceOrderButtonStateForMembers && (updateCheckoutError || placeOrderError)) {
      setPlaceOrderButtonState(StatesButtonStates.IDLE);
      setShouldPlaceOrderButtonBeDisabled(isPlaceOrderButtonDisabled);
    }
  }, [
    updateCheckoutError,
    placeOrderError,
    ShouldRecalculatePlaceOrderButtonStateForMembers,
    isPlaceOrderButtonDisabled,
  ]);

  // eslint-disable-next-line sonarjs/cognitive-complexity
  useEffect(() => {
    if (isFastFlow || updateCheckoutError || placeOrderError) {
      setPlaceOrderButtonState(StatesButtonStates.IDLE);
      setShouldPlaceOrderButtonBeDisabled(isPlaceOrderButtonDisabled);
    } else if (activeStep.stepId === StepId.paymentAndPlaceOrder && shouldRequirePayment && isMember) {
      const disableButtonByBillingSection =
        !experiments.enabled(SPECS.EnablePlaceOrderButtonForExistingNoAddressCustomer) ||
        !shouldHideBillingDetailsSection(checkout, experiments, activePaymentId);
      /* istanbul ignore next */

      const disablePlaceOrderButton =
        !areFormsLoaded ||
        (!areMemberDetailsValid && !editMode && selectedAddressesServiceId !== ADD_NEW_ADDRESS_ID) ||
        editMode ||
        (selectedAddressesServiceId === ADD_NEW_ADDRESS_ID && disableButtonByBillingSection);

      const checkIfPlaceOrderButtonShouldBeDisabledInCaseOfAddressDeleted =
        isShippingValidForBilling && billingSameAsShipping ? false : disablePlaceOrderButton;

      if (placeOrderButtonState !== StatesButtonStates.IN_PROGRESS) {
        setShouldPlaceOrderButtonBeDisabled(
          checkIfPlaceOrderButtonShouldBeDisabledInCaseOfAddressDeleted || isPlaceOrderButtonDisabled
        );
      }
    } else if (ShouldRecalculatePlaceOrderButtonStateForMembers) {
      setPlaceOrderButtonState(StatesButtonStates.IDLE);
      setShouldPlaceOrderButtonBeDisabled(!areFormsLoaded || isPlaceOrderButtonDisabled);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    areFormsLoaded,
    areMemberDetailsValid,
    editMode,
    selectedAddressesServiceId,
    activeStep,
    isFastFlow,
    isPlaceOrderButtonDisabled,
    shouldRequirePayment,
    placeOrderButtonState,
    isMember,
    isShippingValidForBilling,
    billingSameAsShipping,
    experiments,
    activePaymentId,
    updateCheckoutError,
    placeOrderError,
  ]);

  return {
    placeOrderButtonState,
    shouldPlaceOrderButtonBeDisabled,
    setPlaceOrderButtonState,
    setShouldPlaceOrderButtonBeDisabled,
  };
}
